import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { scroller } from 'react-scroll';
import './Footer.css'; // You can create a separate CSS file for styling
import logo from '../Images/Logo.png';
import linkedinIcon from '../Images/linkedin.png';
import instagramIcon from '../Images/instagram.png';
import facebookIcon from '../Images/facebook.png';
import whatappicon from '../Images/whatsappicon.webp';
import phone from '../Images/phone.png';
import location from '../Images/location-pin.png';



function Footer() {

    const scrollTo = (elementId) => {
        scroller.scrollTo(elementId, {
            duration: 500,
            delay: 0,
            smooth: 'easeInOutQuad',
            offset: -70, // Adjust this value based on your navbar height
        });
    };

    return (
        <div className="footer">
            <Container>
                <Row className='footer-first-row'>
                    <Col sm={12} md={6} lg={6} className="logo-follow">
                        <div className="logo">
                            <img src={logo} alt="Company Logo" />
                        </div>
                    </Col>
                    <Col sm={12} md={6} className="footer-links">
                        <div className="follow-us">
                            <span>Follow us:</span>
                            <a href="https://www.linkedin.com/in/lalit-chauhan-08839b48/"><img src={linkedinIcon} alt="LinkedIn" className="social-icon first-icon" /></a>
                            <a href="https://www.instagram.com/lalit612888/"><img src={instagramIcon} alt="Instagram" className="social-icon" /></a>
                            <a href="https://www.facebook.com/lalit.chauhan.397?mibextid=JRoKGi"><img src={facebookIcon} alt="Facebook" className="social-icon" /></a>
                            <a href="https://wa.me/9927612888"><img src={whatappicon} alt="Whats aPP" className="social-icon" /></a>
                        </div>
                    </Col>
                </Row>
                <Row className="footer-second-row">
                    <Col sm={12} md={6} className="footer-links">
                    <ul>
                            <li>
                                <a href="#home" onClick={() => scrollTo("home")}>Home</a>
                            </li>
                            <li>
                                <a href="#products" onClick={() => scrollTo("products")}>Products</a>
                            </li>
                            <li>
                                <a href="#about-us" onClick={() => scrollTo("about-us")}>About Us</a>
                            </li>
                            {/* <li>
                                <a href="#contact-us" onClick={() => scrollTo("contact-us")}>Contact Us on whats App</a>
                            </li> */}
                        </ul>
                    </Col>
                    <Col sm={12} md={6} className="contact-info">
                        <div>
                            <p>Contact Us</p>
                            <p>
                                <a href="tel:+919927612888" className="link">
                                    {/* <img src={phone} alt="Phone Icon" className="icon" /> */}
                                    Phone: +91-992-761-2888
                                </a>
                            </p>
                            <p>
                                <a href="mailto:lalit.chauhan@bhavyapharmaceuticals.com" className="link">
                                    {/* <img src={email} alt="Email Icon" className="icon" /> */}
                                    Email: lalit.chauhan@bhavyapharmaceuticals.com
                                </a>
                            </p>
                            <p>
                                <a href="https://maps.app.goo.gl/5ryGb6ikUKdokBXv9" className="link"> 
                                    {/* <img src={location} alt="Location Icon" className="icon" /> */}
                                    Office Address: Jharkhandi Road Siau, Chandpur, Bijnor, Uttar Pradesh, 246725
                                </a>
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="copyright">
                        &copy; {new Date().getFullYear()} Bhavya Pharmaceutical. All rights reserved.

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;
