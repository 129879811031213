import React from 'react';
import './AboutUs.css'; // You can create a separate CSS file for styling
import { Container, Row, Col } from 'react-bootstrap';
import { Element } from 'react-scroll';

function AboutUs() {


    return (
        <Element name="about-us">
        <div className="about-us-bg">
            <Container className="about-us">
                <Row>
                    <Col lg={12}>
                        <h2 className="about-us-title">About Us</h2>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="section">
                            <h3>Welcome to Bhavya Pharmaceutical</h3>
                            <p>
                                At Bhavya Pharmaceutical, we take pride in our journey as a dynamic and innovative pharmaceutical company specializing in the development and distribution of both Ayurvedic and Allopathic medicines. Our commitment to quality, innovation, and growth has guided us through a remarkable three-year journey, laying the foundation for excellence and progress.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="section">
                            <h3>Our Distinctive Approach</h3>
                            <p>Our passion lies in formulating a diverse range of herbal products, each meticulously crafted to meet the highest quality standards. With an unwavering dedication to purity, efficacy, and safety, we've established new benchmarks in the Ayurvedic industry. Our product offerings not only exemplify the rich heritage of Ayurveda but also embrace modern scientific principles, creating a unique fusion of tradition and innovation.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="section">
                            <h3>A Journey of Success</h3>
                            <p>
                                In our pursuit of excellence, Bhavya Pharmaceutical has emerged as one of the fastest-growing companies in the industry. Our relentless focus on research and development, customer-centric approach, and a commitment to ethical practices have been the cornerstones of our success.
                                We invite you to explore our diverse range of products, each designed to improve the well-being and health of our valued customers. Join us on this exciting journey as we continue to set new standards in the world of pharmaceuticals.
                                Thank you for choosing Bhavya Pharmaceutical.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="section">
                            <h3>Our Mission</h3>
                            <p>At Bhavya Pharmaceutical, our mission is to bring holistic well-being to people's lives by providing safe, effective, and innovative healthcare solutions. We are committed to harnessing the power of Ayurveda and modern medicine to develop a diverse range of high-quality pharmaceutical products. Our aim is to improve the health and quality of life for individuals and communities worldwide. We strive to be a trusted partner in healthcare, making a positive impact through our dedication to research, quality, and customer satisfaction.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className='vision-col'>
                        <div className="section">
                            <h3>Our Vision</h3>
                            <p>Our vision is to be a global leader in the pharmaceutical industry, known for our unwavering commitment to excellence and innovation. We aspire to set new standards in healthcare by continually exploring and embracing advancements in science and technology. Bhavya Pharmaceutical aims to be a symbol of trust, integrity, and transparency. We envision a world where our products contribute to healthier, happier lives for all. Through our dedication to ethical practices, sustainability, and social responsibility, we aim to leave a lasting legacy for future generations.</p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
        </Element>
    );
}

export default AboutUs;
