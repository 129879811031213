import Image1 from '../Images/product1.webp';
import Image2 from '../Images/product2.webp';
import Image3 from '../Images/product3.webp';
import Image4 from '../Images/product4.webp';
import Image5 from '../Images/product5.webp';
import Image6 from '../Images/product6.webp';
import Image7 from '../Images/product7.webp';
import Image8 from '../Images/product8.webp';
import Image9 from '../Images/product9.webp';

export const Products = [
  {
    id: 1,
    src: Image1,
    alt: 'Product One',
    caption: 'A Nature Care Tonic for Liver & Digestive System that helps in improving liver function and digestive health.',
    title: 'FourCure 4G',
    message: 'Hello, I am interested in FourCure 4G. Please provide more details about this Nature Care Tonic for Liver & Digestive System.'
  },
  {
    id: 2,
    src: Image2,
    alt: 'Product Two',
    caption: 'Dual-action Relief Tablet for Allergies and Asthma, designed to alleviate symptoms and improve respiratory function.',
    title: 'LEVONECT-M',
    message: 'Hello, I am interested in LEVONECT-M. Please provide more details about this Dual-action Relief Tablet for Allergies and Asthma.'
  },
  {
    id: 3,
    src: Image3,
    alt: 'Product Three',
    caption: 'Effective Bacterial Infection Treatment for Quicker Recovery, aimed at treating various bacterial infections effectively.',
    title: 'OFNET-200',
    message: 'Hello, I am interested in OFNET-200. Please provide more details about this Effective Bacterial Infection Treatment for Quicker Recovery.'
  },
  {
    id: 4,
    src: Image7,
    alt: 'Product Four',
    caption: 'Daily Multivitamin Solution (Sugar-Free) that supports overall health and wellness without added sugars.',
    title: 'Multiminco-Plus Syrup',
    message: 'Hello, I am interested in Multiminco-Plus Syrup. Please provide more details about this Daily Multivitamin Solution (Sugar-Free).'
  },
  {
    id: 5,
    src: Image8,
    alt: 'Product Five',
    caption: 'Targeted treatment for stomach acid control and ulcer relief, designed to manage acid-related issues effectively.',
    title: 'Rabbi-DSR',
    message: 'Hello, I am interested in Rabbi-DSR. Please provide more details about this Targeted treatment for stomach acid control and ulcer relief.'
  },
  {
    id: 6,
    src: Image9,
    alt: 'Product Six',
    caption: 'Manage acid reflux and improve digestion with this effective capsule formulation.',
    title: 'Omnect 20 Capsule',
    message: 'Hello, I am interested in Omnect 20 Capsule. Please provide more details about this treatment for acid reflux and digestion improvement.'
  }
];