// src/components/ProductSection.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './OurProducts.css'; 
import { Products } from "../utilis/productDetails";
import { Element } from 'react-scroll';

function ProductSection() {

  const handleClick = (message) => {
    const phoneNumber = '9927612888'; 
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Element name="products">
      <div className="product-section">
        <Container>
          <h2 className="section-title">Our Products</h2>
          <Row>
            <Col>
              <div className="product-container">
                {Products.map((product) => (
                  <div
                    key={product.id}
                    className="product-box"
                    onClick={() => handleClick(product.message)}
                  >
                    <img src={product.src} alt={product.alt} className="product-image" />
                    <p><b>{product.title}</b></p>
                    <p className="product-caption">{product.caption}</p>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Element>
  );
}

export default ProductSection;
