import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Container, Row, Col } from 'react-bootstrap';
import './Header.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Products } from "../utilis/productDetails";
import { Element } from 'react-scroll';

const images = Products;

function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Element name="home">
    <div className="header-bg">
       
      <Container>
        <Row>
          <div className="header">
            <Col md={6} lg={6}>
              <Carousel
                showThumbs={false}
                autoPlay={true} // Auto-play the carousel
                interval={5000} // Change slide every 5 seconds
                onChange={handleSlideChange} // Handle slide change event
                infiniteLoop
              >
                {images && images.map((image) => (
                  <div key={image.id}>
                    <img src={image.src} alt={image.alt} className="img-carousel" />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col md={6} lg={6}>
              <div className="image-info">
                <h2>{images[currentSlide].caption}</h2>
                {/* <p>Additional information about the carousel image.</p> */}
              </div>
            </Col>
          </div>
        </Row>
      </Container>
     
    </div>
    </Element>
  );
}

export default Header;
