import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./Navbar/Navbar";
import Header from "./Header/Header";
import OurProducts from "./OurProducts/OurProducts";
import AboutUs from "./AboutUs/AboutUs";
import Footer from "./Footer/Footer"

function App() {
  return (
    <div>
       <Navbar />
       <Header />
       <OurProducts />
       <AboutUs />
       <Footer />
    </div>
  );
}

export default App;
