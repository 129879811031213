import React, { useEffect, useState } from 'react';
import './Navbar.css';
import brandLogo from '../Images/Logo.png';
import { scroller } from 'react-scroll';

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollTo = (elementId) => {
    scroller.scrollTo(elementId, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuad',
        offset: -70, // Adjust this value based on your navbar height
    });
    setIsMobileMenuOpen(false);
};

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
    <div className="navbar-content">
      <div className="navbar-logo"><img src={brandLogo} alt='BhavyaPharmacy Brand Logo' className='brand-logo'></img></div>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
      </div>
    </div>
    <ul className={`navbar-links ${isMobileMenuOpen ? 'open' : ''}`}>
      <li>
        <a href="https://wa.me/9927612888">ContactUs</a>
      </li>
      <li>
        <a href="#products" onClick={() => scrollTo("products")}>Products</a>
      </li>
      <li>
        <a href="#about-us" onClick={() => scrollTo("about-us")}>About Us</a>
      </li>
    </ul>
  </nav>
  );
}

export default Navbar;
